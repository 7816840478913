
import { textSearch } from "@/assets/ts/_utils";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { municipiosComarcas } from "@/core/data/municipiosProvinciasComarcas";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { FamiliaEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, FamiliasListadoModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "familias-listado",
  components: { "download-excel": JsonExcel, Page, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(FamiliasListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      nombre: t("csv.listados.familias.nombre"),
      apellidos: t("csv.listados.familias.apellidos"),
      dni: t("csv.listados.familias.dni"),
      email: t("csv.listados.familias.email"),
      telefono: t("csv.listados.familias.telefono"),
      direccion: t("csv.listados.familias.direccion"),
      localidad: t("csv.listados.familias.localidad"),
      provincia: t("csv.listados.familias.provincia"),
      estado: t("csv.listados.familias.estado"),
      familiaPrioritaria: t(
        "tabla.serviciosSolicitadosListado.cabecera.familiaPrioritaria"
      ),
      ingresos: t("formularios.familiaDetalle.ingresos.label"),
    };
    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "nombre",
      "apellidos",
      "dni",
      "email",
      "telefono",
      "direccion",
      "localidad",
      "provincia",
      "estado",
      "familiaPrioritaria",
      "ingresos",
    ];
    const csvName = "familiasListado" + new Date().getTime() + ".xlsx";

    const search = ref("");
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchFamilias(comarcaId);
        search.value = "";
        currentPage.value = 1;
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.familias"), [
        t("menu.planCorresponsables"),
      ]);
    });
    const handleEdit = (index, row) => {
      router.push(`familias/${row.id}`);
    };

    const familias = computed(() => {
      return module.familiasListado?.map((ele) => {
        return {
          ...ele,
          nombreComarca: ele.comarca?.nombre,
          responsable: [ele.nombre, ele.apellidos].join(" "),
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      familias.value
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.email, search.value) ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.direccion, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.localidad, search.value)
          );
        })
    );

    const { value: localidad } = useField("localidad");

    const familiasListado = computed(() => {
      const filteredBySearch = (familias.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.email, search.value) ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.direccion, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.localidad, search.value)
          );
        });

      if (localidad?.value === undefined || localidad?.value === "") {
        return filteredBySearch;
      } else {
        // Intersección de registros filtrados por búsqueda y por localidad
        return filteredBySearch?.filter((data) =>
          familias.value
            ?.filter((data) => data.localidad == localidad.value)
            ?.includes(data)
        );
      }
    });

    const { result, currentPage, lastPage } = useArrayPagination(
      familiasListado,
      { pageSize: 100 }
    );

    const municipiosComarca = computed(() => {
      if (!comarcaId) {
        return municipiosComarcas;
      } else {
        return municipiosComarcas.filter(
          (municipio) => municipio.comarcaId === comarcaId
        );
      }
    });

    return {
      familiasListado,
      filteredCollection,
      sortIngresos: function (a, b) {
        let primero = isNaN(a.ingresos) ? 0 : a.ingresos;
        let segundo = isNaN(b.ingresos) ? 0 : b.ingresos;

        return primero - segundo;
      },
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
      cargaListado,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      FamiliaEstado,
      search,
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      municipiosComarca,
      localidad,
    };
  },
});
